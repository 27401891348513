(function() {
    'use strict';

    angular.module('uabLocalStorage', [
        'ionicAlert',
        'uabDefaultVariable'
    ]);
})();
(function() {
    'use strict';

    angular.module('uabLocalStorage').service(
        'LocalStorageService',
        LocalStorageService
    );

    LocalStorageService.$inject = [
        'DefaultVariableService',
        'ErrorService'
    ];

    function LocalStorageService(
        DefaultVariableService,
        ErrorService
    ) {
        var LocalStorageService = this;

        LocalStorageService.add = add;
        function add(key, value) {
            if (!DefaultVariableService.isString(key)) {
                key = JSON.stringify(key);
            }
            if (!DefaultVariableService.isString(value)) {
                value = JSON.stringify(value);
            }

            var response = false;
            if (LocalStorageService.exists(key)) {
                ErrorService.addWarning('Item already exists.');
            } else {
                localStorage.setItem(
                    key,
                    value
                );

                response = true;
            }

            return response;
        }

        LocalStorageService.clearAll = clearAll;
        function clearAll() {
            localStorage.clear();
        }

        LocalStorageService.edit = edit;
        function edit(key, value) {
            if (!DefaultVariableService.isString(key)) {
                key = JSON.stringify(key);
            }
            if (!DefaultVariableService.isString(value)) {
                value = JSON.stringify(value);
            }

            var response = false;
            if (LocalStorageService.exists(key)) {
                localStorage.setItem(key, value);

                response = value;
            }

            return response;
        }

        LocalStorageService.exists = exists;
        function exists(key) {
            if (!DefaultVariableService.isString(key)) {
                key = JSON.stringify(key);
            }

            var value = localStorage.getItem(key);

            return value !== null;
        }

        LocalStorageService.get = get;
        function get(key) {
            if (!DefaultVariableService.isString(key)) {
                key = JSON.stringify(key);
            }

            var response = false;
            if (LocalStorageService.exists(key)) {
                response = localStorage.getItem(key);
            }

            return response;
        }

        LocalStorageService.getJson = getJson;
        function getJson(key) {
            return JSON.parse(
                LocalStorageService.get(key)
            );
        }

        LocalStorageService.remove = remove;
        function remove(key) {
            if (!DefaultVariableService.isString(key)) {
                key = JSON.stringify(key);
            }

            var response = false;
            if (LocalStorageService.exists(key)) {
                localStorage.removeItem(key);

                response = true;
            } else {
                ErrorService.addWarning('Item does not exist.');
            }

            return response;
        }

        LocalStorageService.reset = reset;
        function reset() {

        }
        
        LocalStorageService.reset();

        return LocalStorageService;
    }
})();
